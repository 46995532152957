import "./sass/app.scss";

(() => {
  function changeImage() {
    var container = document.querySelector(".imageSlides");
    var images = container.querySelectorAll("img");
    var randIndex = Math.floor(Math.random() * 8);

    images.forEach((img, i) => {
      if (i === randIndex) {
        img.style.opacity = "1";
      } else {
        img.style.opacity = "0";
      }
    });
  }

  changeImage();

  setInterval(function() {
    changeImage();
  }, 9000);
})();
